import * as React from 'react'
import { useEffect, useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'

// COMPONENTS
import Layout from 'layouts/main'

import '../styles/components.css'
import axios from 'axios'
import { config } from '../config'
import queryString from 'querystring'
import Api from '../helpers/api'

const StudentPage = () => {
  const [loading, setLoading] = useState(true)
  const [accessDenied, setAccessDenied] = useState(false)
  const [result, setResult] = useState([])


  useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      let user = (await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)).data
      if (user.role !== 'ROLE_STUDENT') {
        setAccessDenied(true)
        return;
      }
      Api.getInstance().getBeblinTestResult(user.email)
        .then(response => {
        setResult(response)
      })
        .catch(e => {
          console.error(e)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (e) {
      const { id } = queryString.parse(window.location.search.substr(1))
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = `/?showLogin=true&next=/test`
    }
  }, [])


  return (
    <Layout title='Students'>
      <Container className='pb-5 px-100px'>
        <link
          href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
          rel='stylesheet'
          integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
          crossOrigin='anonymous'
        ></link>
        {accessDenied &&
        <div>You don't have permission to access this page.</div>
        }
        {!loading && result.length === 0 &&
        <div>Oops, an error has occurred. </div>
        }
        {loading && !accessDenied &&
        <div>Loading, please wait...</div>
        }
        {!accessDenied && !loading &&
        <>
          <h3 className='highlighted-title display-4 fw-bold'>Team Roles According to Belbin</h3>

          {result.map(({name, description, functionText, characteristics, strengths, weakness}, index)=>{
          return <div>
            <h4 className='fs-35px fw-bold my-5'>{index+1}. {name}</h4>
            <h5 className='fw-medium fs-27px mb-3'>Characteristics</h5>

            <h5 className='fs-22px mb-5'>{characteristics}</h5>
            <h5 className='fs-22px mb-5'>{description}</h5>
            <h5 className='fw-medium fs-27px mb-3'>Function</h5>
            <h5 className='fs-22px mb-5'>{functionText}</h5>
            <h5 className='fw-medium fs-27px'>Strong Sides</h5>
            <ul className='fs-22px'>
              <li>
                {strengths}
              </li>
            </ul>
            <h5 className='fw-medium fs-27px mt-5'>Weak Sides</h5>
            <ul className='fs-22px pb-5'>
              <li>
                {weakness}
              </li>
            </ul>
{/*            <h3 className='highlighted-title display-4 fw-bold my-5'>Temperament Detection</h3>
            <h5 className='fw-medium mt-5 fs-27px mb-5'>According to Eisenhower's personal test, the sanguine type
              predominates</h5>
            <Row>
              <Col md='6'>
                <p>
                  <b>Sanguines</b> balanced and emotionally are stable type of people. They are characterized by
                  irritation
                  and lancing in the braking processor. To them Emotions come quickly and change quickly. To them Haste is
                  typical, possibly on its own and overestimation of opportunities, up to arrogance. But in dangerous
                  situations are accountable, sober, without the risk that is typical to cholera. Sanguines are able to
                  not
                  panic in stressful situations, do not lose self-control in critical moments.</p>
                <p> They strive quickly achieve their goals by working Avoid obstacles. After stressful situations
                  sanguines
                  recover quickly as high is their ability to adapt but to work? Encouragement is very important for them
                  in
                  the process praise. It separates them from life Optimistic-realistic attitude is always good mood, high
                  ability to overcome difficulties. They are not carried away by bad thoughts or fears. Easily make social
                  contacts and communicate with pleasure, but can be vulnerable if surrounded by extreme temperaments.</p>
              </Col>
              <Col md='6'>
                <p>
                  There are such people. At the same time, they can often change their relationship with this or that
                  person, they are unreliable partners, they do not always keep their promises. They tend to be arrogant.
                  In
                  conflict situations, they either cooperate or try to escape from the situation. Monotonous work can
                  cause
                  irritability, discomfort, mood swings. For that purpose, it is necessary to constantly set new tasks at
                  work, which require attention, intellectual work.
                </p>
                <p> It is important not to aggravate them, not to set very dry deadlines, to divide the goal into separate
                  tasks (long projects can exhaust them, make them tired). At the same time, they do not need to be
                  constantly monitored, like cholera patients. The sanguines take criticism lightly. Long-term inactivity
                  or
                  a sharp decrease in social contacts can cause strong affective reactions, so they should also be
                  constantly involved in active and useful work.
                </p>
              </Col>
            </Row>

            <h5 className='fw-medium mt-5 fs-27px mb-5'>An alternative to possible temperament</h5>
            <Row>
              <Col md='6'>
                <p>
                  <b>Phlegmatics</b> have a balanced inert nervous system, which manifests itself in actions, slowness of
                  speech, honesty, reasoning, urgency, and balance. They are characterized by low sensitivity and emotion,
                  less pronounced facial expressions, non-expressive movements and intonation. They respond slowly and
                  calmly to various situations (including stressful ones).
                </p>
                <p>If the response of a choleric to a stimulus is manifested immediately like an explosive, then the
                  phlegmatic needs processes to accumulate the stimulus, and the result must overcome the slowness typical
                  of this type in order to express itself, to come out, to "burst out". And "emptying" can be prolonged
                  and
                  manifest itself long after the provocation. In extreme situations, the reaction of phlegmatic people can
                  be delayed and worsen the relationship and cause misunderstanding in the other person.</p>
                <p> If it is necessary to convince a phlegmatic of something, then it is necessary to bring facts,
                  substantiations, because it is very difficult to convince them on an emotional background. Phlegmatics
                  can
                  not work in a time constraint, they can not be accelerated, but they want a very strict regulation of
                  activity, which increases their resilience and efficiency. </p>
              </Col>
              <Col md='6'>
                <p>
                  Phlegmatics have a balanced inert nervous system, which manifests itself in actions, slowness of speech,
                  honesty, reasoning, urgency, and balance. They are characterized by low sensitivity and emotion, less
                  pronounced facial expressions, non-expressive movements and intonation.
                </p>
                <p>
                  They respond slowly and calmly to various situations (including stressful ones). If the response of a
                  choleric to a stimulus is manifested immediately like an explosive, then the phlegmatic needs processes
                  to
                  accumulate the stimulus, and the result must overcome the slowness typical of this type in order to
                  express itself, to come out, to "burst out". And "emptying" can be prolonged and manifest itself long
                  after the provocation. In extreme situations, the reaction of phlegmatic people can be delayed and
                  worsen
                  the relationship and cause misunderstanding in the other person.
                </p>
                <p>
                  If it is necessary to convince a phlegmatic of something, then it is necessary to bring facts,
                  substantiations, because it is very difficult to convince them on an emotional background. Phlegmatics
                  can
                  not work in a time constraint, they can not be accelerated, but they want a very strict regulation of
                  activity, which increases their resilience and efficiency.
                </p>
              </Col>
            </Row>*/}
          </div>
          })}

        </>
        }


      </Container>
    </Layout>


  )
}

export default StudentPage
